<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="d-flex align-center mb-2">
            <v-tooltip bottom content-class="tooltip-bottom">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off mr-2" small icon @click="$nova.gotoLink({path: '/tds/ar'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
              </template>
              <span>Back to Analysis List</span>
            </v-tooltip>
            <div>
              <v-chip v-if="stage === 3" small label color="success">Closed</v-chip>
              <v-chip v-else-if="stage === 1" small label color="warning">Pending for review</v-chip>
              <v-chip v-else small label color="info">Open</v-chip>
            </div>
            <v-spacer></v-spacer>
            <v-btn class="shadow-off" small text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
          </div>
          <div class="d-flex align-center">
            <div>
              <div class="d-flex align-center">
                <div class="subtitle-1 font-weight-bold mr-2">{{metadata.description}}</div>
              </div>
              <div>
                <span class="mr-2"><v-icon class="mr-1">mdi-calendar-range</v-icon> Period: </span>
                <span class="mr-1">{{$nova.formatDate(metadata.from)}}</span>
                <span class="mr-1">- {{$nova.formatDate(metadata.to)}}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div v-if="(rundetails || {}).stage === 1">
              <v-btn color="primary" class="shadow-off" v-if="stage===0 && !isreviewer" small @click="startAnalysis()">
                <v-icon class="mr-1">mdi-play</v-icon>
                Re-run Analysis
              </v-btn>
              <v-btn color="warning" class="ml-2 shadow-off" v-if="stage===0 && !isreviewer" small @click="sendForReview()">
                <v-icon class="mr-1">mdi-check</v-icon>
                Send for Review
              </v-btn>
              <v-btn color="error" class="ml-2 shadow-off" v-if="stage===1 && isreviewer" small @click="openProject('back')">
                <v-icon class="mr-1">mdi-arrow-left</v-icon>
                Send back to preparer
              </v-btn>
              <v-btn color="success" class="ml-2 shadow-off" v-if="stage===1 && isreviewer" small @click="closeProject()">
                <v-icon class="mr-1">mdi-lock</v-icon>
                Review & Close
              </v-btn>
              <v-btn color="info" class="ml-2 shadow-off" v-if="stage===3 && isreviewer" small @click="openProject()">
                <v-icon class="mr-1">mdi-lock-open-variant</v-icon>
                Re-open Analysis
              </v-btn>
            </div>
            <div v-else-if="isreviewer" class="warning--text">
              Waiting for preparer to finish the analysis
            </div>

          </div>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-alert v-if="[0,4].indexOf((rundetails || {}).stage) > -1" text border="left" class="py-2" color="info" :value="true">
            <div class="runningloader ml-2 mr-4">
              <span class="inner-circle"></span>
              <span class="outer-circle"></span>
            </div>
            This analysis is currently running
          </v-alert>
          <v-alert v-else-if="!Object.hasOwnProperty.call((metadata || {}), 'parameters')" text border="left" class="py-2" type="info" :value="true">
            Save Input Parameters to proceed with the file upload
          </v-alert>
          <v-alert v-else-if="!allfileuploaded" text border="left" class="py-2" type="info" :value="true">
            Upload all the files to start the analysis
            <v-btn color="primary" v-if="!isreviewer && tab!=1" class="ml-2 shadow-off" small @click="tab=1">
              <v-icon class="mr-1">mdi-cloud-upload-outline</v-icon>
              Proceed
            </v-btn>
          </v-alert>
          <v-alert v-else-if="(rundetails || {}).stage === 2" text border="left" class="py-2" type="error" :value="true">
            There was an error while running the analysis. Please re-run after sometime.
            <v-btn color="primary" class="ml-2 shadow-off" small @click="startAnalysis()">
              <v-icon class="mr-1">mdi-play</v-icon>
              Run Analysis
            </v-btn>
          </v-alert>
          <v-alert v-else-if="(Object.hasOwnProperty.call((metadata || {}), 'parameters')) && allfileuploaded && !(Object.hasOwnProperty.call((rundetails || {}), 'stage'))" text border="left" class="py-2" type="info" :value="true">
            Input parameters & Files are saved. Do you want to run the analysis?
            <v-btn color="primary" class="ml-2 shadow-off" small @click="startAnalysis()">
              <v-icon class="mr-1">mdi-play</v-icon>
              Run Analysis
            </v-btn>
          </v-alert>
          <div class="">
            <div class="">
              <v-tabs v-model="tab" height="40">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab :key="0" class="tds-menu-tab text-transform-none justify-start"><v-icon class="mr-2">mdi-play-box-multiple-outline</v-icon>Input Parameters</v-tab>
                <v-tab v-if="(((metadata || {}).parameters || []).length > 0)" :key="1" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-cloud-upload-outline</v-icon>Files</v-tab>
                <v-tab v-if="Object.keys(rundetails).length > 0" :key="2" @click="readAnnexure(formattedannexurelist[1].collection, formattedannexurelist[1].name, formattedannexurelist[1])" class="text-transform-none justify-start"><v-icon class="mr-2">mdi-format-list-bulleted</v-icon>Result</v-tab>
              </v-tabs>
            </div>
            <v-divider></v-divider>
            <div class="flex-grow-1 d-block">
              <v-tabs-items v-model="tab" class="">
                <v-tab-item :key="0">
                  <div>
                    <div class="d-flex my-2">
                      <v-btn v-if="!isreviewer && !running && stage===0" color="primary" small elevation="0" @click="saveParameters()">Save Input Parameters</v-btn>
                      <v-spacer></v-spacer>
                    </div>
                    <div class="" v-for="(pv,pk) in (analysis.parameter || [])" :key="pk">
                      <div class="d-flex hoverable px-4 py-2">
                        <div>
                          <span class="font-weight-bold">{{pk+1}}.</span>
                          <span class="mx-2">{{pv.name || "Name not found!!"}}</span>
                        </div>
                        <v-spacer></v-spacer>
                        <div>
                          <div v-if="pv.type==='number'" class="parameter-input">
                            <lb-number v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='string'" class="parameter-input">
                            <lb-string v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='date'" class="parameter-input">
                            <lb-date v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='year'" class="parameter-input">
                            <lb-year v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='month'" class="parameter-input">
                            <lb-month v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='time'" class="parameter-input">
                            <lb-time v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='daterange'" class="parameter-input">
                            <lb-daterange v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="pv.type==='timerange'" class="parameter-input">
                            <lb-timerange v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer"/>
                          </div>
                          <div v-else-if="['numberarray','stringarray','datearray','yeararray','montharray','timearray','daterangearray','timerangearray'].indexOf(pv.type) > -1" class="parameter-input">
                            <lb-list :allowrepeat="true" :type="pv.type" v-model="inputparametersdata[pv._id]" :hidedetails="true" :disabled="isreviewer" />
                          </div>
                        </div>
                      </div>
                      <v-divider></v-divider>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item :key="1">
                  <FileUploads :files="analysis.file" :filevalues="fileuploaddata" :editable="stage === 0"
                    :storepath="'/v2/tds/ar/analysis/storefile/' + metadata._id" :fileproerr="fileprocesserrors"
                    :fileprostage="fileprocessstatus" :fileuppercent="fileuploadpercent" :filesdataPros="filesdata"
                    :fileconfig="filesconfigdata" @startloading="loading = true" @stoploading="loading = false"
                    @reload="refreshData()" />
                  <!-- <div class="" v-for="(v,k) in analysis.file" :key="k">
                    <div class="pa-2 hoverable" v-if="filecounter">
                      <div class="subtitle-2 d-flex align-center">
                        <div class="font-weight-bold">
                          {{k+1}}. {{v.name || "No name found!!"}}
                          <v-icon color="success" class="ml-2" v-if="(fileuploaddata[v._id] || {}).processed === 1">mdi-check-circle</v-icon>
                          <v-icon color="info" class="ml-2" v-else>mdi-timer-sand</v-icon>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="fileprocessstatus[v._id] === -1" class="ml-2">
                          <span class="error--text">{{fileprocesserrors[v._id] || "Unknown Error"}}</span>
                        </div>
                        <span v-if="fileuploaddata[v._id]" @click="$nova.downloadFile((fileuploaddata[v._id] || {})._id)" class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                          <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
                          {{fileuploaddata[v._id].uploadname}}
                        </span>
                        <div v-if="fileprocessstatus[v._id] === 0 && counter" class="ml-2">
                          <v-chip small color="info" :value="true" label>
                            Validating
                          </v-chip>
                        </div>
                        <div v-else-if="fileprocessstatus[v._id] === 1 && counter" class="ml-2">
                          <v-chip small label color="warning" :value="true">
                            Processing
                          </v-chip>
                        </div>
                        <div v-else-if="fileprocessstatus[v._id] === 2 && counter" class="ml-2">
                          <v-chip small label color="info" :value="true">
                            Uploading
                            {{fileuploadpercent[v._id] + "%"}}
                          </v-chip>
                        </div>
                        <div class="ml-2 d-flex align-center" v-else-if="!isreviewer && !running && stage===0">
                          <lb-file :hidedetails="true" :drag="false" v-model="filesdata[v._id]" accept=".csv,.xlsx,.xls" label="" :displayname="false" @change="processFileChanges(v._id, v.columns || [])"/>
                        </div>
                      </div>
                     
                      <div class="grey--text text--darken-1">Required columns:</div>
                      <div v-if="(filesconfigdata[v._id] || {}).columnmap">
                        <v-chip class="mr-1 mb-1" small v-for="(cv, ck) in (filesconfigdata[v._id] || {}).columnmap" :key="ck">{{cv[1] || "Unknown"}}</v-chip>
                      </div>
                      <div v-else>
                        <v-chip class="mr-1 mb-1" small v-for="(cv, ck) in (v.columns || [])" :key="ck">{{cv.name || ""}}</v-chip>
                      </div>
                    </div>
                    <v-divider></v-divider>
                  </div> -->
                </v-tab-item> 
                <v-tab-item :key="2">
                  <div class="pa-6 text-center title font-weight-bold grey--text" v-if="rundetails.stage === 0 || rundetails.stage === 4">
                    Result will be displayed once the analysis is finished running
                  </div>
                  <div class="pa-6 text-center title font-weight-bold error--text text--lighten-2" v-else-if="rundetails.stage === 2">
                    There was an error while running the analysis.<br/> Please re-run after sometime.
                  </div>
                  <div class="d-flex mt-2" v-else-if="rundetails.stage === 1">
                    <div style="min-width:250px;">
                      <v-btn color="primary" class="mb-4 shadow-off" small @click="downloadResult()">
                        <v-icon class="mr-1">mdi-file-excel</v-icon>
                        Download Excel
                      </v-btn>
                      <template v-for="(v,k) in formattedannexurelist">
                        <div v-if="v.type==='heading'" :key="k" class="border-left-grey px-1 mb-1 font-weight-bold grey--text body-2">{{v.name}}</div>
                        <lb-divider v-else-if="v.type==='divider'" class="my-3" :key="k"></lb-divider>
                        <div v-else :key="k" @click="selectedresulttab=v.name;readAnnexure(v.collection, v.name, v)" :class="`px-1 d-flex align-start cursor-pointer hoverable ${(selectedresulttab===v.name)?'font-weight-bold grey lighten-4':''}`">
                          <v-icon class="mt-1">mdi-chevron-right</v-icon>
                          <span>{{v.name}}</span>
                        </div>
                      </template>
                    </div>
                    <div class="flex-grow-1 px-4">
                      <div class="" v-if="currentannexuredata.length > 0">
                        <!-- <div v-if="enablechart" class="mr-4 mb-5">
                          <canvas ref="summary_chart" height="100" width="800"></canvas>
                        </div> -->
                        <v-simple-table dense class="" height="75vh"  style="max-width:75vw" :fixed-header="true">
                          <template v-slot:default>
                            <thead>
                              <tr class="grey lighten-3">
                                <template v-for="(v,k) in currentannexuredata[0]">
                                  <th class="grey lighten-3" :key="k" v-if="k!=='_id'">{{(k || "").replaceAll("_", " ")}}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in currentannexuredata" :key="k">
                                <template v-for="(vv,kk) in currentannexuredata[0]">
                                  <td :key="kk" v-if="kk!=='_id'">
                                    {{(typeof v[kk] === "number")?$nova.formatNumber(v[kk].toFixed(0)):v[kk]}}
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </div>
                      <div v-else class="text-center grey--text title pa-6">No data available</div>
                    </div>
                  </div>
                </v-tab-item> 
              </v-tabs-items>

            </div>
          </div>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
import {Chart,BarController,CategoryScale,LinearScale,BarElement,Legend,Title,Tooltip} from 'chart.js';
Chart.register(BarController,CategoryScale,LinearScale,BarElement,Legend,Title,Tooltip);
import FileUploads from '../../../views/conserve/FileUploads.vue';
// let xlsx = require('xlsx');

export default {
  name: 'tds_ar_view',
  data: function(){
    return {
      generateddescription: "",
      metadata: {},
      analysis: {},
      filesdata: {},
      isreviewer: false,
      fileuploaddata: {},
      filesconfigdata: {},
      rundetails: {},
      selectedresulttab: "Summary",
      annexuredata: {},
      annexurechartdata: {},
      currentannexuredata: [],
      chartobject: null,
      enablechart: false,
      stage: -1,

      fileprocessstatus: {},
      fileprocesserrors: {},
      fileuploadpercent: {},
      allfileuploaded: false,
      running: false,
      filecounter: 1,
      
      inputparametersdata: {},
      id: "",
      tab: "",
      loading: false,
      counter: 0,
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  components: {
    FileUploads
  },
  methods: {
    refreshData() {
      if(this.$nova.getPermission("tds_ar_project").indexOf('review') > -1) this.isreviewer = true;
      else this.isreviewer = false;
      this.id = this.$route.params.id || "";
      this.annexuredata = {};
      this.getData();
    },
    getData() {
      this.loading = true;
     
      return this.axios.post("/v2/tds/ar/analysis/getdetails/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0] || {}
          this.metadata = data.metadata || {};
          this.stage = this.metadata.stage;
          this.analysis = data.setup || {};
          this.fileuploaddata = data.uploaddetails || {};
          this.rundetails = (data.rundetails || [])[0] || {};
          if([0,4].indexOf(this.rundetails.stage) > -1) this.running = true;
          else this.running = false;
          this.fileprocessstatus = {};
          this.fileprocesserrors = {};
          let hasfileprocesserror = false;
          let inprocessing = false;
          for (const k in this.fileuploaddata) {
            if (Object.hasOwnProperty.call(this.fileuploaddata, k)) {
              const el = this.fileuploaddata[k];
              if(el.processed !== 1 && el.processed !== -1) {
                inprocessing = true;
                this.fileprocessstatus[k] = 1; 
              }
              else if(el.processed === -1) {
                // this.fileprocessstatus[k] = -1;
                this.fileprocesserrors[k] = "Processing Error";
                hasfileprocesserror = true;
              }
            }
          }
          this.counter++;
          this.allfileuploaded = false;
          if(((this.analysis || {}).file || []).length === Object.keys(this.fileuploaddata).length){
            if(!hasfileprocesserror && !inprocessing) this.allfileuploaded = true;
          }
         
          this.inputparametersdata = {};
          for (let i = 0; i < (this.analysis.parameter || []).length; i++) {
            const el = (this.analysis.parameter || [])[i];
            this.inputparametersdata[el._id] = el.value;
          }
          for (const k in (this.analysis.inputparameterconfig || {})) {
            if (Object.hasOwnProperty.call((this.analysis.inputparameterconfig || {}), k)) {
              const el = (this.analysis.inputparameterconfig || {})[k];
              this.inputparametersdata[el.parameter] = el.value;
            }
          }
          for (let i = 0; i < (this.metadata.parameters || []).length; i++) {
            const el = (this.metadata.parameters || [])[i];
            this.inputparametersdata[el.parameterid] = el.value;
          }
          
          
          this.filesconfigdata = {};
          for (const k in (this.analysis.fileconfig || {})) {
            if (Object.hasOwnProperty.call((this.analysis.fileconfig || {}), k)) {
              const el = (this.analysis.fileconfig || {})[k];
            this.filesconfigdata[k] = el;
            }
          }
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    saveParameters() {
      this.loading = true;
      let ndt = [];
      for (const k in this.inputparametersdata) {
        if (Object.hasOwnProperty.call(this.inputparametersdata, k)) {
          const el = this.inputparametersdata[k];
          ndt.push({parameterid: k, value: el});          
        }
      }
      this.axios.post("/v2/tds/ar/analysis/saveparameters/"+this.id, {data: ndt}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Input Parameters Updated");
          this.refreshData();
        }else throw new Error ("Error updating input parameters");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    closeProject() {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/close/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.refreshData();
        }else throw new Error ("Error closing project");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    readAnnexure(c, name="", data) {
      // console.log(data);
      if(this.annexuredata[c]) {
        this.currentannexuredata = this.annexuredata[c];
        if(name === "Summary" && (((data.file || "").toString().toLowerCase() || "consark") === "consark") ) {
          this.enablechart = true;
          this.$nextTick(() => {
            this.setChartData();
          });
        }else this.enablechart = false;
      }
      else{
        this.loading = true;
        Promise.resolve().then(() => {
          return this.axios.post("/v2/tds/ar/analysis/readannexure/"+this.id+"/c/"+c);
        }).then(dt => {
          if(dt.data.status === "success"){
            this.annexuredata[c] = dt.data.data;
            this.currentannexuredata = this.annexuredata[c]
            if(name === "Summary") {
              this.enablechart = true;
              this.$nextTick(() => {
                this.setChartData();
              });
            }else this.enablechart = false;
          }else throw new Error ("Error reading annexure");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    setChartData() {
      console.log(this.currentannexuredata);
      let labels = [];
      let datasetslist = {};
      let datasets = [];
      let totals = {};
      let colors = this.$nova.getColors(this.currentannexuredata.length -1,0.8)
      let el = this.currentannexuredata[0] || [];
      for (const k in el) {
        if (Object.hasOwnProperty.call(el, k)) {
          if(k !== "_id" && k !== "Particulars" && k !== "Previous_AS_Amount") {
            labels.push(k.replaceAll(" ", "_"));
          }
        }
      }
      for (let i = 0; i < this.currentannexuredata.length; i++) {
        const el = this.currentannexuredata[i];
        if(el.Particulars === "Total") totals = el;
      }
      for (let i = 0; i < this.currentannexuredata.length; i++) {
        const el = this.currentannexuredata[i];
        let nk = el.Particulars.replaceAll(" ", "_");
        if(nk !== "Total" && nk !== "Unmatched_Balances"){
          datasetslist[nk] = datasetslist[nk] || {label: el.Particulars, data: [], backgroundColor: colors[i], barThickness: 20};
          if(Object.keys(totals).length > 0) datasetslist[nk].data = labels.map(x => {console.log(nk,x,el[x],totals[x]);return (((el[x]/totals[x]) || 0)*100).toFixed(2)});
          else datasetslist[nk].data = labels.map(x => {return (el[x] || 0).toFixed(0)});
        }
      }
      for (const k in datasetslist) {
        if (Object.hasOwnProperty.call(datasetslist, k)) {
          const el = datasetslist[k];
          datasets.push(el);
        }
      }
      for (let i = 0; i < labels.length; i++) {
        const el = labels[i];
        if(el==="Current_AS_Amount") labels[i] = "26 AS";
        else if(el==="GL_Amount") labels[i] = "GL";
      }
      this.annexurechartdata = {
        labels: labels,
        datasets: datasets
      };
      if (this.chartobject) this.chartobject.destroy();
      this.chartobject = new Chart(this.$refs['summary_chart'].getContext("2d"), {
          title: "asdf",
          type: 'bar',
          data: this.annexurechartdata,
          options: {
            indexAxis: 'y',
            legend: {
                display: false
            },
            scales: {
              x: {
                  stacked: true,
                  display: false,
                  grid: {
                    display: false
                  }
              },
              y: {
                  stacked: true,
                  grid: {
                    display: false
                  }
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                text: 'Percentage Comparison'
              }
            }
          }
      });
    },
    downloadResult() {
      this.loading = true;
      this.axios.post("/v2/tds/ar/analysis/downloadfile/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          console.log(dt.data.data);
          let data = dt.data.data[0] || {};
          if(data.stage === 0 || data.stage === 4) this.$store.commit("sbSuccess", "File is being generated please try after sometime");
          else if(data.stage === 2) this.$store.commit("sbSuccess", "There was an error while generating file");
          else if(data.upload) this.$nova.downloadFile(data.upload);
          else return  "no file found to download"
        }else {
          throw new Error (dt.data.message || "Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    openProject(t="open") {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/open/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          if(t === "back") this.$store.commit("sbSuccess", "Project sent back to preparer");
          else this.$store.commit("sbSuccess", "Project Re-opened");
          this.refreshData();         
        }else {
          if(t === "back") throw new Error ("Error sending back");
          else  throw new Error ("Error re-opening project");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    sendForReview() {
      this.loading = true;
      this.axios.post("/v2/tds/ar/project/sendforreview/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project sent for review");
          this.refreshData();
        }else throw new Error ("Error while sending for review");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    // processFileChanges(id, columns = []) {
    //   if(this.filesdata[id] && this.fileprocessstatus[id] !== 0){
    //     this.fileprocessstatus[id] = 0;
    //     let thisfileconfigdata = {};
    //     Promise.resolve().then(() => {
    //       if(!this.filesdata[id]) throw new Error ("This file is required!");
    //       else if(columns.length === 0) throw new Error ("0 columns found in master");
    //       else if(!this.filesdata[id].name) throw new Error ("Upload a valid file");
    //       else{
    //         this.fileprocesserrors[id] = "";
    //         let file = this.filesdata[id];
    //         if(['xls','xlsx','csv'].includes(file.name.split('.').pop())){
    //           if(['xls','xlsx'].includes(file.name.split('.').pop()) && file.size > (1024*1024*2)){
    //             throw new Error ("File with size > 2MB should be in CSV format");
    //           }else{

    //             if(Object.prototype.hasOwnProperty.call(this.filesconfigdata,id)){
    //               thisfileconfigdata = this.filesconfigdata[id] || {};
    //             }
    //            return this.checkUploadFileHeaders(this.filesdata[id], columns, this.filesdata[id].name.split('.').pop(), thisfileconfigdata);
    //           }
    //         }else throw new Error ("Upload only XLSX, XLS or CSV files");
    //       }
    //     }).then((dt = {}) => {
    //       if(dt.duplicates) throw new Error ("Duplicates found: "+ dt.duplicates.join(","));
    //       else if((dt.missingcols || []).length > 0) throw new Error ("'" + dt.missingcols.join(", ") + "' column(s) not found");
    //       else if((dt.data || []).length > 0){
    //         this.fileprocessstatus[id] = 2;
    //         this.counter++;
    //         return this.$nova.uploadFile(this.axios, this.filesdata[id], {
    //           progressfunction: (e) => {
    //             let p = e.loaded*100/e.total;
    //             this.counter++;
    //             this.fileuploadpercent[id] = p.toFixed(0);
    //           }
    //         });
    //       }else throw new "no data found to process";
    //     }).then(dt => {
    //       if(dt.data.status === "success" && dt.data.data.length > 0){
    //         this.fileprocessstatus[id] = 1;
    //         this.counter++;
    //         let data = {upload: dt.data.data[0]._id, file: id, config: thisfileconfigdata};
    //         let url = "/v2/tds/ar/analysis/storefile/"+this.id;
    //         return this.axios.post(url, {data: [data]});
    //       }else throw new "Unable to upload file";
    //     }).then(dt => {
    //       if(dt.data.status === "success"){
    //         this.refreshData();
    //         this.filesdata[id] = null;
    //         this.fileprocesserrors[id] = "";
    //       }else throw new dt.data.message || "error uploading file";
    //     }).catch(err => {
    //       console.log(err);
    //       this.$store.commit("sbError", err.message || err || "Error while processing the file");
    //       this.filesdata[id] = null;
    //       this.fileprocessstatus[id] = -1;
    //       this.fileprocesserrors[id] = err.message || err || "Error while processing the file";
    //     }).finally(() => {
    //     });
    //   }
    // },
    startAnalysis: function(){
      this.loading = true;
      this.axios.post("/v2/tds/ar/analysis/start/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Analysis started");
          this.refreshData();
        }else throw new Error ("Error starting analysis");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })    
    },
    // checkUploadFileHeaders: function(f, cols, fmt, ops={}){
    //   let t = Number(ops.ignorerows) || 0;
    //   let l = Number(ops.ignorecols) || 0;
    //   let c = [];
    //   for (const i of cols) {
    //     c.push(i.name);
    //   }
    //   if(ops.columnmap instanceof Array){
    //     c = [];
    //     ops.columnmap.forEach(x => {c.push(x[1] || x[0])});
    //   }
    //   console.log("Check columns",t,l,c,fmt,ops);
    //   return new Promise((resolve, reject) => {
    //     let readCSVFile = new FileReader();
    //     let headerFile = new FileReader();
    //     let hf = null;
    //     readCSVFile.onload = function(e) {
    //       let d = e.target.result.split('\n');
         
    //       if(d.length >= t+1){
           
    //         let hd = d.slice(0, t+25);
    //         hd = hd.join("\n");
            
            
    //         hf = new File([hd], 'tfile', {type:'text/csv'});
    //         headerFile.readAsBinaryString(hf);
    //       }
    //       else{
    //         reject(Error("Invalid File"));
    //       }
    //     }
    //     readCSVFile.onerror = function(ex) {reject(ex)};
    //     headerFile.onload = function(e) {
    //       let d = e.target.result;
          
    //       let wb = xlsx.read(d, {type: 'binary', cellHTML: false, raw: true, codepage: 1252}); 
    //       let rdt = [];
          
    //       let sh = wb.SheetNames[0];
          
    //       let dt = xlsx.utils.sheet_to_json(wb.Sheets[sh], {header: 1, raw: false});
          
    //       if(ops.removeblankrows){
    //           let ndt = [];
    //           for (const i of dt.length) {
    //             if(i.length !== 0 && i.join("").length !== 0) ndt.push(i);
    //           }
    //          dt = ndt;
    //       }
    //       dt = dt.splice(Math.max(t-1,0),dt.length);
          
    //       let prevrowkey = Math.min(t-1, 0);
    //       let prevrow = dt[prevrowkey] || [];
    //       let h = dt[prevrowkey+1] || [];
    //       let nextrow = dt[prevrowkey+2] || [];
    //       let flag = true;
    //       for (let i = 0; i < h.length; i++) {
    //         const el = h[i];
    //         if(!el){
    //             if(ops.blankcolumnheaders === "bottom") h[i] = nextrow[i] || "";
    //             else if(ops.blankcolumnheaders === "top") h[i] = prevrow[i] || "";
    //             if(ops.blankcolumnheaders === "left") h[i] = h[i-1] || "";
    //             if(ops.blankcolumnheaders === "right") h[i] = h[i+1] || "";
    //         }
    //       }
          
    //       let missingcols = [];
    //       h = h.slice(l,h.length);
    //       h = h.map(x => {return x.toString().trim();})
          
    //       if(ops.keepduplicatecolumns){
    //         for (let i = 0; i < h.length; i++) {
    //           const el = h[i];
    //           let counter = 1;
    //           for (let j = i+1; j < h.length; j++) {
    //             const oel = h[j];
    //             if(oel === el){
    //               if(counter === 1) {
    //                 h[i] = el+"_"+counter;
    //                 counter = counter+1;
    //               }
    //               h[j] = oel+"_"+counter;
    //               counter = counter+1;
    //             }
    //           }
    //         }
    //       }
          
    //       for (let i = 0; i < c.length; i++) {
    //         let el = c[i].toString().trim();
    //         if(h.indexOf(el) === -1) {
    //           missingcols.push(c[i].toString());
    //           flag = false;
    //         }            
    //       }
    //       if(flag){
    //         rdt = dt;
    //         if(l > 0){
    //           rdt = rdt.map(el => {
    //             return el.slice(l,el.length);
    //           });
    //         }
    //       }
         
    //       resolve({data: rdt, missingcols: missingcols});
    //     }
    //     headerFile.onerror = function(ex) {reject(ex)};
    //     if(fmt == "csv") readCSVFile.readAsText(f.slice(0, 1024*1024*20));
    //     else headerFile.readAsBinaryString(f);
    //   });
    // },
    // uploadFile(file, fid){
    //   if(this.metadata.parameters){
    //     if(this.fileprocessstatus[fid] == 0){
         
    //       this.fileprocessstatus[fid] = 1;
          
    //       return new Promise((resolve, reject) => {
           
    //       this.$nova.uploadFile(this.axios, file, {
    //           progressfunction: (e) => {
    //             let p = e.loaded*100/e.total;
    //             this.counter++;
    //             this.fileuploadpercent[fid] = p.toFixed(0);
    //           }
    //         }).then(x => {
    //           if(x.data.status === "success"){
    //             this.filesdata[fid] = null;
    //             this.fileprocessstatus[fid] = 2;
                
    //             resolve(x);
    //           }else reject(Error(x.data.message));
    //         }).catch(e => {
    //           reject(e);
    //         });
    //       });
    //     }
    //   }else{
    //     this.$store.commit("sbError", "Cannot store file before storing the parameters");
    //   }
    // },
  },
  computed: {
    formattedannexurelist: function(){
      let dta = [];
      let catdata = {};
      for (let i = 0; i < ((this.rundetails || {}).annexure || []).length; i++) {
        const el = ((this.rundetails || {}).annexure || [])[i];
        let cat = el.file || "Consark";
        catdata[cat] = catdata[cat] || [];
        catdata[cat].push({...el});
      }
      if((catdata["Consark"] || []).length > 0){
        dta.push({type: 'heading', name: "Consark"});
        dta = [...dta, ...catdata["Consark"]];
        dta.push({type: 'divider'});
      }
      for (const k in catdata) {
        if (Object.hasOwnProperty.call(catdata, k)) {
          if(k !== "Consark"){
            const el = catdata[k];
            dta.push({type: 'heading', name: k});
            dta = [...dta, ...el];
            dta.push({type: 'divider'});
          }
        }
      }
      return dta;
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "addeditproject.from": function(){
      this.generateDescription();
    },
    "addeditproject.to": function(){
      this.generateDescription();
    },
    "$route": function(d){
      if(d.hash === "#add") {
        this.addprojectdialog = true;
        this.addeditproject = {};
        this.addeditproject.reminderdays = this.addeditproject.reminderdays ?? 7;
      }
    },
    "addprojectdialog": function(d){
      if(!d) this.$nova.gotoLink({hash: ''});
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-tabs .v-tab {
  height: 40px !important;
}
</style>